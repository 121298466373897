// JsFromRoutes CacheKey 03416fd59136e54d0465f3194f4d2f19
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  finish: definePathHelper('post', '/documents/finish'),
  entries: definePathHelper('get', '/documents/:id/entries'),
  state: definePathHelper('get', '/documents/:id/state'),
  saveTitle: definePathHelper('post', '/documents/:id/save_title'),
  updateNotifications: definePathHelper('post', '/documents/:id/update_notifications'),
}
